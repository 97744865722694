import * as React from "react"
import Layout from '../components/Layout'
import { Typography } from "@material-ui/core"
import generalCss from "../styles/generalCss"


const PrivacyPolicyPage = () => {
    return (
        <div>
            <Layout showContact={false} pageSeo={{ SEO_TITLE: 'Privacy Policy' }}>
                <div style={{ padding: '2rem', maxWidth: generalCss.appMaxWidth }}>
                    <Typography variant="h1" component='h1' style={{ marginBottom: '10px' }}>Privacy Policy</Typography>
                    <Typography variant="body2">This privacy policy applies to the Black Rook app (hereby referred to as "Application") for mobile devices that was
                        created by Black Rook (hereby referred to as "Service Provider") as a Free service. This service is intended for use
                        "AS IS".</Typography>
                    <Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Information Collection and Use</Typography>
                    <Typography variant="body2">The Application collects information when you download and use it. This information may include information such as
                    </Typography>
                    <ul>
                        <li><Typography variant="body2">Your device's Internet Protocol address (e.g. IP address)</Typography></li>
                        <li><Typography variant="body2">The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</Typography></li>
                        <li><Typography variant="body2">The time spent on the Application</Typography></li>
                        <li><Typography variant="body2">The operating system you use on your mobile device</Typography></li>
                    </ul>

                    <Typography variant="body2">The Application does not gather precise information about the location of your mobile device.</Typography>
                    <div style={{ display: 'none' }}>
                        <Typography variant="body2">The Application collects your device's location, which helps the Service Provider determine your approximate
                            geographical location and make use of in below ways:</Typography>
                        <ul>
                            <li><Typography variant="body2">Geolocation Services: The Service Provider utilizes location data to provide features such as personalized
                                content, relevant recommendations, and location-based services.</Typography></li>
                            <li><Typography variant="body2">Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider to analyze user
                                behavior, identify trends, and improve the overall performance and functionality of the Application.</Typography></li>
                            <li><Typography variant="body2">Third-Party Services: Periodically, the Service Provider may transmit anonymized location data to external
                                services. These services assist them in enhancing the Application and optimizing their offerings.</Typography></li>
                        </ul>
                    </div>
                    <Typography variant="body2">The Service Provider may use the information you provided to contact you from time to time to provide you with
                        important information, required notices and marketing promotions.</Typography>
                    <Typography variant="body2">For a better experience, while using the Application, the Service Provider may require you to provide us with
                        certain personally identifiable information, including but not limited to Name, Email, Phone. The information that
                        the Service Provider request will be retained by them and used as described in this privacy policy.</Typography>
                    <Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Third Party Access</Typography>
                    <Typography variant="body2">Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in
                        improving the Application and their service. The Service Provider may share your information with third parties in
                        the ways that are described in this privacy statement.</Typography>
                    <Typography variant="body2">The Service Provider may disclose User Provided and Automatically Collected Information:</Typography>
                    <ul>
                        <li><Typography variant="body2">as required by law, such as to comply with a subpoena, or similar legal process;</Typography></li>
                        <li><Typography variant="body2">when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the
                            safety of others, investigate fraud, or respond to a government request;</Typography></li>
                        <li><Typography variant="body2">with their trusted services providers who work on their behalf, do not have an independent use of the
                            information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</Typography></li>
                    </ul>
                    <Typography variant="body2"></Typography><Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Opt-Out Rights</Typography>
                    <Typography variant="body2">You can stop all collection of information by the Application easily by uninstalling it. You may use the standard
                        uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or
                        network.</Typography><Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Data Retention Policy</Typography>
                    <Typography variant="body2">The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable
                        time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please
                        contact them at accounts@blackrooksoft.com and they will respond in a reasonable time.</Typography>
                    <Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Children</Typography>
                    <Typography variant="body2">The Service Provider does not use the Application to knowingly solicit data from or market to children under the
                        age of 13.</Typography>
                    <div>
                        <Typography variant="body2">The Service Provider does not knowingly collect personally identifiable information from children. The Service
                            Provider encourages all children to never submit any personally identifiable information through the Application
                            and/or Services. The Service Provider encourage parents and legal guardians to monitor their children's Internet
                            usage and to help enforce this Policy by instructing their children never to provide personally identifiable
                            information through the Application and/or Services without their permission. If you have reason to believe that a
                            child has provided personally identifiable information to the Service Provider through the Application and/or
                            Services, please contact the Service Provider (accounts@blackrooksoft.com) so that they will be able to take the
                            necessary actions. You must also be at least 16 years of age to consent to the processing of your personally
                            identifiable information in your country (in some countries we may allow your parent or guardian to do so on your
                            behalf).</Typography>
                    </div><Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Security</Typography>
                    <Typography variant="body2">The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider
                        provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and
                        maintains.</Typography><Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Changes</Typography>
                    <Typography variant="body2">This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any
                        changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this
                        Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</Typography>
                    <Typography variant="body2">This privacy policy is effective as of 2024-03-26</Typography><Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Your Consent</Typography>
                    <Typography variant="body2">By using the Application, you are consenting to the processing of your information as set forth in this Privacy
                        Policy now and as amended by us.</Typography>

                    <Typography variant="h6" component='h2' style={{ marginBottom: "10px", marginTop: "20px" }}>Contact Us</Typography>
                    <Typography variant="body2">If you have any questions regarding privacy while using the Application, or have questions about the practices,
                        please contact the Service Provider via email at accounts@blackrooksoft.com.</Typography>

                    <Typography variant="body2">This privacy policy page was generated by{' '}
                        <Typography component="a" variant="body2" href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">
                            App Privacy Policy Generator
                        </Typography>
                    </Typography>
                </div>
            </Layout>
        </div>
    )
}
export default PrivacyPolicyPage
